@font-face {
  font-family: "Sawton Circular";
  src: url(../fonts/SawtonCircular-Bold.woff2) format("woff2"), url(../fonts/SawtonCircular-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Circular";
  src: url(../fonts/SawtonCircular-Medium.woff2) format("woff2"), url(../fonts/SawtonCircular-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Circular";
  src: url(../fonts/SawtonCircular-Thin.woff2) format("woff2"), url(../fonts/SawtonCircular-Thin.woff) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Circular";
  src: url(../fonts/SawtonCircular-Regular.woff2) format("woff2"), url(../fonts/SawtonCircular-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Industrial";
  src: url(../fonts/SawtonIndustrial-Medium.woff2) format("woff2"), url(../fonts/SawtonIndustrial-Medium.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Circular";
  src: url(../fonts/SawtonCircular-Light.woff2) format("woff2"), url(../fonts/SawtonCircular-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Industrial";
  src: url(../fonts/SawtonIndustrial-Light.woff2) format("woff2"), url(../fonts/SawtonIndustrial-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Industrial";
  src: url(../fonts/SawtonIndustrial-Thin.woff2) format("woff2"), url(../fonts/SawtonIndustrial-Thin.woff) format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Industrial";
  src: url(../fonts/SawtonIndustrial-Regular.woff2) format("woff2"), url(../fonts/SawtonIndustrial-Regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Sawton Industrial";
  src: url(../fonts/SawtonIndustrial-Bold.woff2) format("woff2"), url(../fonts/SawtonIndustrial-Bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap; }

.login-page {
  font-family: "Sawton Industrial", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625;
  color: #515e79;
  text-align: left;
  background-color: #f3f8ff;
  padding: 0; }
  .login-page h1,
  .login-page h2,
  .login-page h3,
  .login-page h4,
  .login-page h5,
  .login-page h6 {
    margin-top: 0;
    margin-bottom: 1rem; }
  .login-page p {
    margin-top: 0;
    margin-bottom: 1rem; }
  .login-page ul {
    margin-top: 0;
    margin-bottom: 1rem; }
  .login-page ul ul {
    margin-bottom: 0; }
  .login-page a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent; }
  .login-page a:hover {
    color: #0056b3;
    text-decoration: underline; }
  .login-page a:not([href]) {
    color: inherit;
    text-decoration: none; }
  .login-page a:not([href]):hover {
    color: inherit;
    text-decoration: none; }
  .login-page code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em; }
  .login-page figure {
    margin: 0 0 1rem; }
  .login-page img {
    vertical-align: middle;
    border-style: none; }
  .login-page svg {
    overflow: hidden;
    vertical-align: middle; }
  .login-page label {
    display: inline-block;
    margin-bottom: 0.5rem; }
  .login-page button {
    -webkit-border-radius: 0;
    border-radius: 0; }
  .login-page button,
  .login-page input,
  .login-page textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }
  .login-page button,
  .login-page input {
    overflow: visible; }
  .login-page button {
    text-transform: none; }
  .login-page [role="button"] {
    cursor: pointer; }
  .login-page [type="button"],
  .login-page [type="reset"],
  .login-page [type="submit"],
  .login-page button {
    -webkit-appearance: button; }
  .login-page [type="button"]:not(:disabled),
  .login-page [type="reset"]:not(:disabled),
  .login-page [type="submit"]:not(:disabled),
  .login-page button:not(:disabled) {
    cursor: pointer; }
  .login-page [type="button"]::-moz-focus-inner,
  .login-page [type="reset"]::-moz-focus-inner,
  .login-page [type="submit"]::-moz-focus-inner,
  .login-page button::-moz-focus-inner {
    padding: 0;
    border-style: none; }
  .login-page input[type="checkbox"],
  .login-page input[type="radio"] {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0; }
  .login-page textarea {
    overflow: auto;
    resize: vertical; }
  .login-page [type="number"]::-webkit-inner-spin-button,
  .login-page [type="number"]::-webkit-outer-spin-button {
    height: auto; }
  .login-page [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }
  .login-page [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }
  .login-page ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }
  .login-page [hidden] {
    display: none !important; }
  .login-page .h1,
  .login-page .h2,
  .login-page .h3,
  .login-page .h4,
  .login-page .h5,
  .login-page .h6,
  .login-page h1,
  .login-page h2,
  .login-page h3,
  .login-page h4,
  .login-page h5,
  .login-page h6 {
    margin-bottom: 1rem;
    font-weight: 500;
    line-height: 1.2; }
  .login-page .h1,
  .login-page h1 {
    font-size: 2.5rem; }
  .login-page .h2,
  .login-page h2 {
    font-size: 2rem; }
  .login-page .h3,
  .login-page h3 {
    font-size: 1.75rem; }
  .login-page .h4,
  .login-page h4 {
    font-size: 1.5rem; }
  .login-page .h5,
  .login-page h5 {
    font-size: 1.25rem; }
  .login-page .h6,
  .login-page h6 {
    font-size: 1rem; }
  .login-page .navbar {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0.5rem 1rem; }
  .login-page .navbar .container,
  .login-page .navbar .container-fluid,
  .login-page .navbar .container-lg,
  .login-page .navbar .container-md,
  .login-page .navbar .container-xl {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }
  .login-page .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    width: auto;
    padding-left: 0; }
    .login-page .navbar-brand img {
      height: auto; }
  .login-page .navbar-brand:focus,
  .login-page .navbar-brand:hover {
    text-decoration: none; }
  .login-page .navbar-nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .login-page .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .login-page .navbar-nav .dropdown-menu {
    position: static;
    float: none; }
  .login-page .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .login-page .navbar-collapse {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .login-page .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    -webkit-border-radius: 0.25rem;
    border-radius: 0.25rem; }
  .login-page .navbar-toggler:focus,
  .login-page .navbar-toggler:hover {
    text-decoration: none; }
  .login-page .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }
  @media (max-width: 767.98px) {
    .login-page .navbar-expand-md > .container,
    .login-page .navbar-expand-md > .container-fluid,
    .login-page .navbar-expand-md > .container-lg,
    .login-page .navbar-expand-md > .container-md,
    .login-page .navbar-expand-md > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 768px) {
    .login-page .navbar-expand-md {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .login-page .navbar-expand-md .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
    .login-page .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute; }
    .login-page .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .login-page .navbar-expand-md > .container,
    .login-page .navbar-expand-md > .container-fluid,
    .login-page .navbar-expand-md > .container-lg,
    .login-page .navbar-expand-md > .container-md,
    .login-page .navbar-expand-md > .container-xl {
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .login-page .navbar-expand-md .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .login-page .navbar-expand-md .navbar-toggler {
      display: none; } }
  @media (max-width: 991.98px) {
    .login-page .navbar-expand-lg > .container,
    .login-page .navbar-expand-lg > .container-fluid,
    .login-page .navbar-expand-lg > .container-lg,
    .login-page .navbar-expand-lg > .container-md,
    .login-page .navbar-expand-lg > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 992px) {
    .login-page .navbar-expand-lg {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .login-page .navbar-expand-lg .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
    .login-page .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute; }
    .login-page .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .login-page .navbar-expand-lg > .container,
    .login-page .navbar-expand-lg > .container-fluid,
    .login-page .navbar-expand-lg > .container-lg,
    .login-page .navbar-expand-lg > .container-md,
    .login-page .navbar-expand-lg > .container-xl {
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .login-page .navbar-expand-lg .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .login-page .navbar-expand-lg .navbar-toggler {
      display: none; } }
  @media (max-width: 1199.98px) {
    .login-page .navbar-expand-xl > .container,
    .login-page .navbar-expand-xl > .container-fluid,
    .login-page .navbar-expand-xl > .container-lg,
    .login-page .navbar-expand-xl > .container-md,
    .login-page .navbar-expand-xl > .container-xl {
      padding-right: 0;
      padding-left: 0; } }
  @media (min-width: 1200px) {
    .login-page .navbar-expand-xl {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-flow: row nowrap;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .login-page .navbar-expand-xl .navbar-nav {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row; }
    .login-page .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute; }
    .login-page .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
    .login-page .navbar-expand-xl > .container,
    .login-page .navbar-expand-xl > .container-fluid,
    .login-page .navbar-expand-xl > .container-lg,
    .login-page .navbar-expand-xl > .container-md,
    .login-page .navbar-expand-xl > .container-xl {
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap; }
    .login-page .navbar-expand-xl .navbar-collapse {
      display: -webkit-box !important;
      display: -webkit-flex !important;
      display: -ms-flexbox !important;
      display: flex !important;
      -webkit-flex-basis: auto;
      -ms-flex-preferred-size: auto;
      flex-basis: auto; }
    .login-page .navbar-expand-xl .navbar-toggler {
      display: none; } }
  .login-page .navbar-expand {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start; }
  .login-page .navbar-expand > .container,
  .login-page .navbar-expand > .container-fluid,
  .login-page .navbar-expand > .container-lg,
  .login-page .navbar-expand > .container-md,
  .login-page .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .login-page .navbar-expand .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row; }
  .login-page .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute; }
  .login-page .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem; }
  .login-page .navbar-expand > .container,
  .login-page .navbar-expand > .container-fluid,
  .login-page .navbar-expand > .container-lg,
  .login-page .navbar-expand > .container-md,
  .login-page .navbar-expand > .container-xl {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
  .login-page .navbar-expand .navbar-collapse {
    display: -webkit-box !important;
    display: -webkit-flex !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-flex-basis: auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto; }
  .login-page .navbar-expand .navbar-toggler {
    display: none; }
  .login-page .nav {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .login-page .nav-link {
    display: block;
    padding: 0.5rem 1rem; }
  .login-page .nav-link:focus,
  .login-page .nav-link:hover {
    text-decoration: none; }
  .login-page .nav-tabs {
    border-bottom: 1px solid #dee2e6; }
  .login-page .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .login-page .nav-tabs .nav-link {
    border: 1px solid transparent;
    -webkit-border-top-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    -webkit-border-top-right-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .login-page .nav-tabs .nav-link:focus,
  .login-page .nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6; }
  .login-page .nav-tabs .nav-item.show .nav-link,
  .login-page .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .login-page .nav-tabs .dropdown-menu {
    margin-top: -1px;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0; }
  .login-page .nav-fill .nav-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center; }
  .login-page .tab-content > .tab-pane {
    display: none; }
  .login-page .tab-content > .active {
    display: block; }
  .login-page .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    .login-page .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .login-page .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .login-page .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .login-page .container {
      max-width: 1170px; } }
  .login-page .container-fluid,
  .login-page .container-lg,
  .login-page .container-md,
  .login-page .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 576px) {
    .login-page .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .login-page .container,
    .login-page .container-md {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .login-page .container,
    .login-page .container-lg,
    .login-page .container-md {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .login-page .container,
    .login-page .container-lg,
    .login-page .container-md,
    .login-page .container-xl {
      max-width: 1170px; } }
  .login-page .row {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }
  .login-page .col,
  .login-page .col-1,
  .login-page .col-10,
  .login-page .col-11,
  .login-page .col-12,
  .login-page .col-2,
  .login-page .col-3,
  .login-page .col-4,
  .login-page .col-5,
  .login-page .col-6,
  .login-page .col-7,
  .login-page .col-8,
  .login-page .col-9,
  .login-page .col-auto,
  .login-page .col-lg,
  .login-page .col-lg-1,
  .login-page .col-lg-10,
  .login-page .col-lg-11,
  .login-page .col-lg-12,
  .login-page .col-lg-2,
  .login-page .col-lg-3,
  .login-page .col-lg-4,
  .login-page .col-lg-5,
  .login-page .col-lg-6,
  .login-page .col-lg-7,
  .login-page .col-lg-8,
  .login-page .col-lg-9,
  .login-page .col-lg-auto,
  .login-page .col-md,
  .login-page .col-md-1,
  .login-page .col-md-10,
  .login-page .col-md-11,
  .login-page .col-md-12,
  .login-page .col-md-2,
  .login-page .col-md-3,
  .login-page .col-md-4,
  .login-page .col-md-5,
  .login-page .col-md-6,
  .login-page .col-md-7,
  .login-page .col-md-8,
  .login-page .col-md-9,
  .login-page .col-md-auto,
  .login-page .col-xl,
  .login-page .col-xl-1,
  .login-page .col-xl-10,
  .login-page .col-xl-11,
  .login-page .col-xl-12,
  .login-page .col-xl-2,
  .login-page .col-xl-3,
  .login-page .col-xl-4,
  .login-page .col-xl-5,
  .login-page .col-xl-6,
  .login-page .col-xl-7,
  .login-page .col-xl-8,
  .login-page .col-xl-9,
  .login-page .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px; }
  .login-page .col {
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .login-page .row-cols-1 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .login-page .row-cols-2 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .login-page .row-cols-3 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .login-page .row-cols-4 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .login-page .row-cols-5 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20%;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%; }
  .login-page .row-cols-6 > * {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .login-page .col-auto {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .login-page .col-1 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 8.33333%;
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .login-page .col-2 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 16.66667%;
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .login-page .col-3 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 25%;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .login-page .col-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 33.33333%;
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .login-page .col-5 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 41.66667%;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .login-page .col-6 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 50%;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .login-page .col-7 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 58.33333%;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .login-page .col-8 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 66.66667%;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .login-page .col-9 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 75%;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .login-page .col-10 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 83.33333%;
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .login-page .col-11 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 91.66667%;
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .login-page .col-12 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  @media (min-width: 768px) {
    .login-page .col-md {
      -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%; }
    .login-page .row-cols-md-1 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .login-page .row-cols-md-2 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .login-page .row-cols-md-3 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .login-page .row-cols-md-4 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .login-page .row-cols-md-5 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 20%;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%; }
    .login-page .row-cols-md-6 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .login-page .col-md-auto {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .login-page .col-md-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.33333%;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .login-page .col-md-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .login-page .col-md-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .login-page .col-md-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .login-page .col-md-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.66667%;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .login-page .col-md-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .login-page .col-md-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.33333%;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .login-page .col-md-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .login-page .col-md-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .login-page .col-md-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.33333%;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .login-page .col-md-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.66667%;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .login-page .col-md-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; } }
  @media (min-width: 992px) {
    .login-page .col-lg {
      -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%; }
    .login-page .row-cols-lg-1 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .login-page .row-cols-lg-2 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .login-page .row-cols-lg-3 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .login-page .row-cols-lg-4 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .login-page .row-cols-lg-5 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 20%;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%; }
    .login-page .row-cols-lg-6 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .login-page .col-lg-auto {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .login-page .col-lg-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.33333%;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .login-page .col-lg-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .login-page .col-lg-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .login-page .col-lg-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .login-page .col-lg-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.66667%;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .login-page .col-lg-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .login-page .col-lg-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.33333%;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .login-page .col-lg-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .login-page .col-lg-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .login-page .col-lg-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.33333%;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .login-page .col-lg-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.66667%;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .login-page .col-lg-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; } }
  @media (min-width: 1200px) {
    .login-page .col-xl {
      -webkit-flex-basis: 0;
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -webkit-box-flex: 1;
      -webkit-flex-grow: 1;
      -ms-flex-positive: 1;
      flex-grow: 1;
      min-width: 0;
      max-width: 100%; }
    .login-page .row-cols-xl-1 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; }
    .login-page .row-cols-xl-2 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .login-page .row-cols-xl-3 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .login-page .row-cols-xl-4 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .login-page .row-cols-xl-5 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 20%;
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%; }
    .login-page .row-cols-xl-6 > * {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .login-page .col-xl-auto {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 auto;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: 100%; }
    .login-page .col-xl-1 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 8.33333%;
      -ms-flex: 0 0 8.33333%;
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .login-page .col-xl-2 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 16.66667%;
      -ms-flex: 0 0 16.66667%;
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .login-page .col-xl-3 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%; }
    .login-page .col-xl-4 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 33.33333%;
      -ms-flex: 0 0 33.33333%;
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .login-page .col-xl-5 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 41.66667%;
      -ms-flex: 0 0 41.66667%;
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .login-page .col-xl-6 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 50%;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%; }
    .login-page .col-xl-7 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 58.33333%;
      -ms-flex: 0 0 58.33333%;
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .login-page .col-xl-8 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 66.66667%;
      -ms-flex: 0 0 66.66667%;
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .login-page .col-xl-9 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 75%;
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%; }
    .login-page .col-xl-10 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 83.33333%;
      -ms-flex: 0 0 83.33333%;
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .login-page .col-xl-11 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 91.66667%;
      -ms-flex: 0 0 91.66667%;
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .login-page .col-xl-12 {
      -webkit-box-flex: 0;
      -webkit-flex: 0 0 100%;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%; } }
  .login-page .h1,
  .login-page .h2,
  .login-page .h3,
  .login-page .h4,
  .login-page .h5,
  .login-page .h6,
  .login-page h1,
  .login-page h2,
  .login-page h3,
  .login-page h4,
  .login-page h5,
  .login-page h6 {
    margin-bottom: 1rem;
    font-weight: 500;
    line-height: 1.2; }
  .login-page .h1,
  .login-page h1 {
    font-size: 2.5rem; }
  .login-page .h2,
  .login-page h2 {
    font-size: 2rem; }
  .login-page .h3,
  .login-page h3 {
    font-size: 1.75rem; }
  .login-page .h4,
  .login-page h4 {
    font-size: 1.5rem; }
  .login-page .h5,
  .login-page h5 {
    font-size: 1.25rem; }
  .login-page .h6,
  .login-page h6 {
    font-size: 1rem; }
  .login-page .align-top {
    vertical-align: top !important; }
  .login-page .align-text-top {
    vertical-align: text-top !important; }
  .login-page .bg-primary {
    background-color: #007bff !important; }
  .login-page a.bg-primary:focus,
  .login-page a.bg-primary:hover,
  .login-page button.bg-primary:focus,
  .login-page button.bg-primary:hover {
    background-color: #0062cc !important; }
  .login-page .bg-info {
    background-color: #17a2b8 !important; }
  .login-page a.bg-info:focus,
  .login-page a.bg-info:hover,
  .login-page button.bg-info:focus,
  .login-page button.bg-info:hover {
    background-color: #117a8b !important; }
  .login-page .bg-white {
    background-color: #fff !important; }
  .login-page .d-block {
    display: block !important; }
  @media (min-width: 768px) {
    .login-page .d-md-block {
      display: block !important; } }
  @media (min-width: 992px) {
    .login-page .d-lg-block {
      display: block !important; } }
  @media (min-width: 1200px) {
    .login-page .d-xl-block {
      display: block !important; } }
  .login-page .align-items-end {
    -webkit-box-align: end !important;
    -webkit-align-items: flex-end !important;
    -ms-flex-align: end !important;
    align-items: flex-end !important; }
  .login-page .align-items-center {
    -webkit-box-align: center !important;
    -webkit-align-items: center !important;
    -ms-flex-align: center !important;
    align-items: center !important; }
  .login-page .align-content-end {
    -webkit-align-content: flex-end !important;
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important; }
  .login-page .align-content-center {
    -webkit-align-content: center !important;
    -ms-flex-line-pack: center !important;
    align-content: center !important; }
  @media (min-width: 768px) {
    .login-page .align-items-md-end {
      -webkit-box-align: end !important;
      -webkit-align-items: flex-end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .login-page .align-items-md-center {
      -webkit-box-align: center !important;
      -webkit-align-items: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .login-page .align-content-md-end {
      -webkit-align-content: flex-end !important;
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .login-page .align-content-md-center {
      -webkit-align-content: center !important;
      -ms-flex-line-pack: center !important;
      align-content: center !important; } }
  @media (min-width: 992px) {
    .login-page .align-items-lg-end {
      -webkit-box-align: end !important;
      -webkit-align-items: flex-end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .login-page .align-items-lg-center {
      -webkit-box-align: center !important;
      -webkit-align-items: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .login-page .align-content-lg-end {
      -webkit-align-content: flex-end !important;
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .login-page .align-content-lg-center {
      -webkit-align-content: center !important;
      -ms-flex-line-pack: center !important;
      align-content: center !important; } }
  @media (min-width: 1200px) {
    .login-page .align-items-xl-end {
      -webkit-box-align: end !important;
      -webkit-align-items: flex-end !important;
      -ms-flex-align: end !important;
      align-items: flex-end !important; }
    .login-page .align-items-xl-center {
      -webkit-box-align: center !important;
      -webkit-align-items: center !important;
      -ms-flex-align: center !important;
      align-items: center !important; }
    .login-page .align-content-xl-end {
      -webkit-align-content: flex-end !important;
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }
    .login-page .align-content-xl-center {
      -webkit-align-content: center !important;
      -ms-flex-line-pack: center !important;
      align-content: center !important; } }
  .login-page .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }
  @supports (position: -webkit-sticky) or (position: sticky) {
    .login-page .sticky-top {
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1020; } }
  .login-page .w-25 {
    width: 25% !important; }
  .login-page .w-50 {
    width: 50% !important; }
  .login-page .w-75 {
    width: 75% !important; }
  .login-page .w-100 {
    width: 100% !important; }
  .login-page .w-auto {
    width: auto !important; }
  .login-page .h-25 {
    height: 25% !important; }
  .login-page .h-50 {
    height: 50% !important; }
  .login-page .h-75 {
    height: 75% !important; }
  .login-page .h-100 {
    height: 100% !important; }
  .login-page .h-auto {
    height: auto !important; }
  .login-page .m-0 {
    margin: 0 !important; }
  .login-page .mt-0,
  .login-page .my-0 {
    margin-top: 0 !important; }
  .login-page .mr-0,
  .login-page .mx-0 {
    margin-right: 0 !important; }
  .login-page .mb-0,
  .login-page .my-0 {
    margin-bottom: 0 !important; }
  .login-page .mx-0 {
    margin-left: 0 !important; }
  .login-page .m-1 {
    margin: 0.25rem !important; }
  .login-page .mt-1,
  .login-page .my-1 {
    margin-top: 0.25rem !important; }
  .login-page .mr-1,
  .login-page .mx-1 {
    margin-right: 0.25rem !important; }
  .login-page .mb-1,
  .login-page .my-1 {
    margin-bottom: 0.25rem !important; }
  .login-page .mx-1 {
    margin-left: 0.25rem !important; }
  .login-page .m-2 {
    margin: 0.5rem !important; }
  .login-page .mt-2,
  .login-page .my-2 {
    margin-top: 0.5rem !important; }
  .login-page .mr-2,
  .login-page .mx-2 {
    margin-right: 0.5rem !important; }
  .login-page .mb-2,
  .login-page .my-2 {
    margin-bottom: 0.5rem !important; }
  .login-page .mx-2 {
    margin-left: 0.5rem !important; }
  .login-page .m-3 {
    margin: 1rem !important; }
  .login-page .mt-3,
  .login-page .my-3 {
    margin-top: 1rem !important; }
  .login-page .mr-3,
  .login-page .mx-3 {
    margin-right: 1rem !important; }
  .login-page .mb-3,
  .login-page .my-3 {
    margin-bottom: 1rem !important; }
  .login-page .mx-3 {
    margin-left: 1rem !important; }
  .login-page .m-4 {
    margin: 1.5rem !important; }
  .login-page .mt-4,
  .login-page .my-4 {
    margin-top: 1.5rem !important; }
  .login-page .mr-4,
  .login-page .mx-4 {
    margin-right: 1.5rem !important; }
  .login-page .mb-4,
  .login-page .my-4 {
    margin-bottom: 1.5rem !important; }
  .login-page .mx-4 {
    margin-left: 1.5rem !important; }
  .login-page .m-5 {
    margin: 3rem !important; }
  .login-page .mt-5,
  .login-page .my-5 {
    margin-top: 3rem !important; }
  .login-page .mr-5,
  .login-page .mx-5 {
    margin-right: 3rem !important; }
  .login-page .mb-5,
  .login-page .my-5 {
    margin-bottom: 3rem !important; }
  .login-page .mx-5 {
    margin-left: 3rem !important; }
  .login-page .p-0 {
    padding: 0 !important; }
  .login-page .pt-0 {
    padding-top: 0 !important; }
  .login-page .pb-0 {
    padding-bottom: 0 !important; }
  .login-page .p-1 {
    padding: 0.25rem !important; }
  .login-page .pt-1 {
    padding-top: 0.25rem !important; }
  .login-page .pb-1 {
    padding-bottom: 0.25rem !important; }
  .login-page .p-2 {
    padding: 0.5rem !important; }
  .login-page .pt-2 {
    padding-top: 0.5rem !important; }
  .login-page .pb-2 {
    padding-bottom: 0.5rem !important; }
  .login-page .p-3 {
    padding: 1rem !important; }
  .login-page .pt-3 {
    padding-top: 1rem !important; }
  .login-page .pb-3 {
    padding-bottom: 1rem !important; }
  .login-page .p-4 {
    padding: 1.5rem !important; }
  .login-page .pt-4 {
    padding-top: 1.5rem !important; }
  .login-page .pb-4 {
    padding-bottom: 1.5rem !important; }
  .login-page .p-5 {
    padding: 3rem !important; }
  .login-page .pt-5 {
    padding-top: 3rem !important; }
  .login-page .pb-5 {
    padding-bottom: 3rem !important; }
  .login-page .m-n1 {
    margin: -0.25rem !important; }
  .login-page .mt-n1,
  .login-page .my-n1 {
    margin-top: -0.25rem !important; }
  .login-page .mr-n1,
  .login-page .mx-n1 {
    margin-right: -0.25rem !important; }
  .login-page .mb-n1,
  .login-page .my-n1 {
    margin-bottom: -0.25rem !important; }
  .login-page .mx-n1 {
    margin-left: -0.25rem !important; }
  .login-page .m-n2 {
    margin: -0.5rem !important; }
  .login-page .mt-n2,
  .login-page .my-n2 {
    margin-top: -0.5rem !important; }
  .login-page .mr-n2,
  .login-page .mx-n2 {
    margin-right: -0.5rem !important; }
  .login-page .mb-n2,
  .login-page .my-n2 {
    margin-bottom: -0.5rem !important; }
  .login-page .mx-n2 {
    margin-left: -0.5rem !important; }
  .login-page .m-n3 {
    margin: -1rem !important; }
  .login-page .mt-n3,
  .login-page .my-n3 {
    margin-top: -1rem !important; }
  .login-page .mr-n3,
  .login-page .mx-n3 {
    margin-right: -1rem !important; }
  .login-page .mb-n3,
  .login-page .my-n3 {
    margin-bottom: -1rem !important; }
  .login-page .mx-n3 {
    margin-left: -1rem !important; }
  .login-page .m-n4 {
    margin: -1.5rem !important; }
  .login-page .mt-n4,
  .login-page .my-n4 {
    margin-top: -1.5rem !important; }
  .login-page .mr-n4,
  .login-page .mx-n4 {
    margin-right: -1.5rem !important; }
  .login-page .mb-n4,
  .login-page .my-n4 {
    margin-bottom: -1.5rem !important; }
  .login-page .mx-n4 {
    margin-left: -1.5rem !important; }
  .login-page .m-n5 {
    margin: -3rem !important; }
  .login-page .mt-n5,
  .login-page .my-n5 {
    margin-top: -3rem !important; }
  .login-page .mr-n5,
  .login-page .mx-n5 {
    margin-right: -3rem !important; }
  .login-page .mb-n5,
  .login-page .my-n5 {
    margin-bottom: -3rem !important; }
  .login-page .mx-n5 {
    margin-left: -3rem !important; }
  .login-page .m-auto {
    margin: auto !important; }
  .login-page .mt-auto,
  .login-page .my-auto {
    margin-top: auto !important; }
  .login-page .mr-auto,
  .login-page .mx-auto {
    margin-right: auto !important; }
  .login-page .mb-auto,
  .login-page .my-auto {
    margin-bottom: auto !important; }
  .login-page .mx-auto {
    margin-left: auto !important; }
  @media (min-width: 768px) {
    .login-page .m-md-0 {
      margin: 0 !important; }
    .login-page .mt-md-0,
    .login-page .my-md-0 {
      margin-top: 0 !important; }
    .login-page .mr-md-0,
    .login-page .mx-md-0 {
      margin-right: 0 !important; }
    .login-page .mb-md-0,
    .login-page .my-md-0 {
      margin-bottom: 0 !important; }
    .login-page .mx-md-0 {
      margin-left: 0 !important; }
    .login-page .m-md-1 {
      margin: 0.25rem !important; }
    .login-page .mt-md-1,
    .login-page .my-md-1 {
      margin-top: 0.25rem !important; }
    .login-page .mr-md-1,
    .login-page .mx-md-1 {
      margin-right: 0.25rem !important; }
    .login-page .mb-md-1,
    .login-page .my-md-1 {
      margin-bottom: 0.25rem !important; }
    .login-page .mx-md-1 {
      margin-left: 0.25rem !important; }
    .login-page .m-md-2 {
      margin: 0.5rem !important; }
    .login-page .mt-md-2,
    .login-page .my-md-2 {
      margin-top: 0.5rem !important; }
    .login-page .mr-md-2,
    .login-page .mx-md-2 {
      margin-right: 0.5rem !important; }
    .login-page .mb-md-2,
    .login-page .my-md-2 {
      margin-bottom: 0.5rem !important; }
    .login-page .mx-md-2 {
      margin-left: 0.5rem !important; }
    .login-page .m-md-3 {
      margin: 1rem !important; }
    .login-page .mt-md-3,
    .login-page .my-md-3 {
      margin-top: 1rem !important; }
    .login-page .mr-md-3,
    .login-page .mx-md-3 {
      margin-right: 1rem !important; }
    .login-page .mb-md-3,
    .login-page .my-md-3 {
      margin-bottom: 1rem !important; }
    .login-page .mx-md-3 {
      margin-left: 1rem !important; }
    .login-page .m-md-4 {
      margin: 1.5rem !important; }
    .login-page .mt-md-4,
    .login-page .my-md-4 {
      margin-top: 1.5rem !important; }
    .login-page .mr-md-4,
    .login-page .mx-md-4 {
      margin-right: 1.5rem !important; }
    .login-page .mb-md-4,
    .login-page .my-md-4 {
      margin-bottom: 1.5rem !important; }
    .login-page .mx-md-4 {
      margin-left: 1.5rem !important; }
    .login-page .m-md-5 {
      margin: 3rem !important; }
    .login-page .mt-md-5,
    .login-page .my-md-5 {
      margin-top: 3rem !important; }
    .login-page .mr-md-5,
    .login-page .mx-md-5 {
      margin-right: 3rem !important; }
    .login-page .mb-md-5,
    .login-page .my-md-5 {
      margin-bottom: 3rem !important; }
    .login-page .mx-md-5 {
      margin-left: 3rem !important; }
    .login-page .p-md-0 {
      padding: 0 !important; }
    .login-page .pt-md-0 {
      padding-top: 0 !important; }
    .login-page .pb-md-0 {
      padding-bottom: 0 !important; }
    .login-page .p-md-1 {
      padding: 0.25rem !important; }
    .login-page .pt-md-1 {
      padding-top: 0.25rem !important; }
    .login-page .pb-md-1 {
      padding-bottom: 0.25rem !important; }
    .login-page .p-md-2 {
      padding: 0.5rem !important; }
    .login-page .pt-md-2 {
      padding-top: 0.5rem !important; }
    .login-page .pb-md-2 {
      padding-bottom: 0.5rem !important; }
    .login-page .p-md-3 {
      padding: 1rem !important; }
    .login-page .pt-md-3 {
      padding-top: 1rem !important; }
    .login-page .pb-md-3 {
      padding-bottom: 1rem !important; }
    .login-page .p-md-4 {
      padding: 1.5rem !important; }
    .login-page .pt-md-4 {
      padding-top: 1.5rem !important; }
    .login-page .pb-md-4 {
      padding-bottom: 1.5rem !important; }
    .login-page .p-md-5 {
      padding: 3rem !important; }
    .login-page .pt-md-5 {
      padding-top: 3rem !important; }
    .login-page .pb-md-5 {
      padding-bottom: 3rem !important; }
    .login-page .m-md-n1 {
      margin: -0.25rem !important; }
    .login-page .mt-md-n1,
    .login-page .my-md-n1 {
      margin-top: -0.25rem !important; }
    .login-page .mr-md-n1,
    .login-page .mx-md-n1 {
      margin-right: -0.25rem !important; }
    .login-page .mb-md-n1,
    .login-page .my-md-n1 {
      margin-bottom: -0.25rem !important; }
    .login-page .mx-md-n1 {
      margin-left: -0.25rem !important; }
    .login-page .m-md-n2 {
      margin: -0.5rem !important; }
    .login-page .mt-md-n2,
    .login-page .my-md-n2 {
      margin-top: -0.5rem !important; }
    .login-page .mr-md-n2,
    .login-page .mx-md-n2 {
      margin-right: -0.5rem !important; }
    .login-page .mb-md-n2,
    .login-page .my-md-n2 {
      margin-bottom: -0.5rem !important; }
    .login-page .mx-md-n2 {
      margin-left: -0.5rem !important; }
    .login-page .m-md-n3 {
      margin: -1rem !important; }
    .login-page .mt-md-n3,
    .login-page .my-md-n3 {
      margin-top: -1rem !important; }
    .login-page .mr-md-n3,
    .login-page .mx-md-n3 {
      margin-right: -1rem !important; }
    .login-page .mb-md-n3,
    .login-page .my-md-n3 {
      margin-bottom: -1rem !important; }
    .login-page .mx-md-n3 {
      margin-left: -1rem !important; }
    .login-page .m-md-n4 {
      margin: -1.5rem !important; }
    .login-page .mt-md-n4,
    .login-page .my-md-n4 {
      margin-top: -1.5rem !important; }
    .login-page .mr-md-n4,
    .login-page .mx-md-n4 {
      margin-right: -1.5rem !important; }
    .login-page .mb-md-n4,
    .login-page .my-md-n4 {
      margin-bottom: -1.5rem !important; }
    .login-page .mx-md-n4 {
      margin-left: -1.5rem !important; }
    .login-page .m-md-n5 {
      margin: -3rem !important; }
    .login-page .mt-md-n5,
    .login-page .my-md-n5 {
      margin-top: -3rem !important; }
    .login-page .mr-md-n5,
    .login-page .mx-md-n5 {
      margin-right: -3rem !important; }
    .login-page .mb-md-n5,
    .login-page .my-md-n5 {
      margin-bottom: -3rem !important; }
    .login-page .mx-md-n5 {
      margin-left: -3rem !important; }
    .login-page .m-md-auto {
      margin: auto !important; }
    .login-page .mt-md-auto,
    .login-page .my-md-auto {
      margin-top: auto !important; }
    .login-page .mr-md-auto,
    .login-page .mx-md-auto {
      margin-right: auto !important; }
    .login-page .mb-md-auto,
    .login-page .my-md-auto {
      margin-bottom: auto !important; }
    .login-page .mx-md-auto {
      margin-left: auto !important; } }
  @media (min-width: 992px) {
    .login-page .m-lg-0 {
      margin: 0 !important; }
    .login-page .mt-lg-0,
    .login-page .my-lg-0 {
      margin-top: 0 !important; }
    .login-page .mr-lg-0,
    .login-page .mx-lg-0 {
      margin-right: 0 !important; }
    .login-page .mb-lg-0,
    .login-page .my-lg-0 {
      margin-bottom: 0 !important; }
    .login-page .mx-lg-0 {
      margin-left: 0 !important; }
    .login-page .m-lg-1 {
      margin: 0.25rem !important; }
    .login-page .mt-lg-1,
    .login-page .my-lg-1 {
      margin-top: 0.25rem !important; }
    .login-page .mr-lg-1,
    .login-page .mx-lg-1 {
      margin-right: 0.25rem !important; }
    .login-page .mb-lg-1,
    .login-page .my-lg-1 {
      margin-bottom: 0.25rem !important; }
    .login-page .mx-lg-1 {
      margin-left: 0.25rem !important; }
    .login-page .m-lg-2 {
      margin: 0.5rem !important; }
    .login-page .mt-lg-2,
    .login-page .my-lg-2 {
      margin-top: 0.5rem !important; }
    .login-page .mr-lg-2,
    .login-page .mx-lg-2 {
      margin-right: 0.5rem !important; }
    .login-page .mb-lg-2,
    .login-page .my-lg-2 {
      margin-bottom: 0.5rem !important; }
    .login-page .mx-lg-2 {
      margin-left: 0.5rem !important; }
    .login-page .m-lg-3 {
      margin: 1rem !important; }
    .login-page .mt-lg-3,
    .login-page .my-lg-3 {
      margin-top: 1rem !important; }
    .login-page .mr-lg-3,
    .login-page .mx-lg-3 {
      margin-right: 1rem !important; }
    .login-page .mb-lg-3,
    .login-page .my-lg-3 {
      margin-bottom: 1rem !important; }
    .login-page .mx-lg-3 {
      margin-left: 1rem !important; }
    .login-page .m-lg-4 {
      margin: 1.5rem !important; }
    .login-page .mt-lg-4,
    .login-page .my-lg-4 {
      margin-top: 1.5rem !important; }
    .login-page .mr-lg-4,
    .login-page .mx-lg-4 {
      margin-right: 1.5rem !important; }
    .login-page .mb-lg-4,
    .login-page .my-lg-4 {
      margin-bottom: 1.5rem !important; }
    .login-page .mx-lg-4 {
      margin-left: 1.5rem !important; }
    .login-page .m-lg-5 {
      margin: 3rem !important; }
    .login-page .mt-lg-5,
    .login-page .my-lg-5 {
      margin-top: 3rem !important; }
    .login-page .mr-lg-5,
    .login-page .mx-lg-5 {
      margin-right: 3rem !important; }
    .login-page .mb-lg-5,
    .login-page .my-lg-5 {
      margin-bottom: 3rem !important; }
    .login-page .mx-lg-5 {
      margin-left: 3rem !important; }
    .login-page .p-lg-0 {
      padding: 0 !important; }
    .login-page .pt-lg-0 {
      padding-top: 0 !important; }
    .login-page .pb-lg-0 {
      padding-bottom: 0 !important; }
    .login-page .p-lg-1 {
      padding: 0.25rem !important; }
    .login-page .pt-lg-1 {
      padding-top: 0.25rem !important; }
    .login-page .pb-lg-1 {
      padding-bottom: 0.25rem !important; }
    .login-page .p-lg-2 {
      padding: 0.5rem !important; }
    .login-page .pt-lg-2 {
      padding-top: 0.5rem !important; }
    .login-page .pb-lg-2 {
      padding-bottom: 0.5rem !important; }
    .login-page .p-lg-3 {
      padding: 1rem !important; }
    .login-page .pt-lg-3 {
      padding-top: 1rem !important; }
    .login-page .pb-lg-3 {
      padding-bottom: 1rem !important; }
    .login-page .p-lg-4 {
      padding: 1.5rem !important; }
    .login-page .pt-lg-4 {
      padding-top: 1.5rem !important; }
    .login-page .pb-lg-4 {
      padding-bottom: 1.5rem !important; }
    .login-page .p-lg-5 {
      padding: 3rem !important; }
    .login-page .pt-lg-5 {
      padding-top: 3rem !important; }
    .login-page .pb-lg-5 {
      padding-bottom: 3rem !important; }
    .login-page .m-lg-n1 {
      margin: -0.25rem !important; }
    .login-page .mt-lg-n1,
    .login-page .my-lg-n1 {
      margin-top: -0.25rem !important; }
    .login-page .mr-lg-n1,
    .login-page .mx-lg-n1 {
      margin-right: -0.25rem !important; }
    .login-page .mb-lg-n1,
    .login-page .my-lg-n1 {
      margin-bottom: -0.25rem !important; }
    .login-page .mx-lg-n1 {
      margin-left: -0.25rem !important; }
    .login-page .m-lg-n2 {
      margin: -0.5rem !important; }
    .login-page .mt-lg-n2,
    .login-page .my-lg-n2 {
      margin-top: -0.5rem !important; }
    .login-page .mr-lg-n2,
    .login-page .mx-lg-n2 {
      margin-right: -0.5rem !important; }
    .login-page .mb-lg-n2,
    .login-page .my-lg-n2 {
      margin-bottom: -0.5rem !important; }
    .login-page .mx-lg-n2 {
      margin-left: -0.5rem !important; }
    .login-page .m-lg-n3 {
      margin: -1rem !important; }
    .login-page .mt-lg-n3,
    .login-page .my-lg-n3 {
      margin-top: -1rem !important; }
    .login-page .mr-lg-n3,
    .login-page .mx-lg-n3 {
      margin-right: -1rem !important; }
    .login-page .mb-lg-n3,
    .login-page .my-lg-n3 {
      margin-bottom: -1rem !important; }
    .login-page .mx-lg-n3 {
      margin-left: -1rem !important; }
    .login-page .m-lg-n4 {
      margin: -1.5rem !important; }
    .login-page .mt-lg-n4,
    .login-page .my-lg-n4 {
      margin-top: -1.5rem !important; }
    .login-page .mr-lg-n4,
    .login-page .mx-lg-n4 {
      margin-right: -1.5rem !important; }
    .login-page .mb-lg-n4,
    .login-page .my-lg-n4 {
      margin-bottom: -1.5rem !important; }
    .login-page .mx-lg-n4 {
      margin-left: -1.5rem !important; }
    .login-page .m-lg-n5 {
      margin: -3rem !important; }
    .login-page .mt-lg-n5,
    .login-page .my-lg-n5 {
      margin-top: -3rem !important; }
    .login-page .mr-lg-n5,
    .login-page .mx-lg-n5 {
      margin-right: -3rem !important; }
    .login-page .mb-lg-n5,
    .login-page .my-lg-n5 {
      margin-bottom: -3rem !important; }
    .login-page .mx-lg-n5 {
      margin-left: -3rem !important; }
    .login-page .m-lg-auto {
      margin: auto !important; }
    .login-page .mt-lg-auto,
    .login-page .my-lg-auto {
      margin-top: auto !important; }
    .login-page .mr-lg-auto,
    .login-page .mx-lg-auto {
      margin-right: auto !important; }
    .login-page .mb-lg-auto,
    .login-page .my-lg-auto {
      margin-bottom: auto !important; }
    .login-page .mx-lg-auto {
      margin-left: auto !important; } }
  @media (min-width: 1200px) {
    .login-page .m-xl-0 {
      margin: 0 !important; }
    .login-page .mt-xl-0,
    .login-page .my-xl-0 {
      margin-top: 0 !important; }
    .login-page .mr-xl-0,
    .login-page .mx-xl-0 {
      margin-right: 0 !important; }
    .login-page .mb-xl-0,
    .login-page .my-xl-0 {
      margin-bottom: 0 !important; }
    .login-page .mx-xl-0 {
      margin-left: 0 !important; }
    .login-page .m-xl-1 {
      margin: 0.25rem !important; }
    .login-page .mt-xl-1,
    .login-page .my-xl-1 {
      margin-top: 0.25rem !important; }
    .login-page .mr-xl-1,
    .login-page .mx-xl-1 {
      margin-right: 0.25rem !important; }
    .login-page .mb-xl-1,
    .login-page .my-xl-1 {
      margin-bottom: 0.25rem !important; }
    .login-page .mx-xl-1 {
      margin-left: 0.25rem !important; }
    .login-page .m-xl-2 {
      margin: 0.5rem !important; }
    .login-page .mt-xl-2,
    .login-page .my-xl-2 {
      margin-top: 0.5rem !important; }
    .login-page .mr-xl-2,
    .login-page .mx-xl-2 {
      margin-right: 0.5rem !important; }
    .login-page .mb-xl-2,
    .login-page .my-xl-2 {
      margin-bottom: 0.5rem !important; }
    .login-page .mx-xl-2 {
      margin-left: 0.5rem !important; }
    .login-page .m-xl-3 {
      margin: 1rem !important; }
    .login-page .mt-xl-3,
    .login-page .my-xl-3 {
      margin-top: 1rem !important; }
    .login-page .mr-xl-3,
    .login-page .mx-xl-3 {
      margin-right: 1rem !important; }
    .login-page .mb-xl-3,
    .login-page .my-xl-3 {
      margin-bottom: 1rem !important; }
    .login-page .mx-xl-3 {
      margin-left: 1rem !important; }
    .login-page .m-xl-4 {
      margin: 1.5rem !important; }
    .login-page .mt-xl-4,
    .login-page .my-xl-4 {
      margin-top: 1.5rem !important; }
    .login-page .mr-xl-4,
    .login-page .mx-xl-4 {
      margin-right: 1.5rem !important; }
    .login-page .mb-xl-4,
    .login-page .my-xl-4 {
      margin-bottom: 1.5rem !important; }
    .login-page .mx-xl-4 {
      margin-left: 1.5rem !important; }
    .login-page .m-xl-5 {
      margin: 3rem !important; }
    .login-page .mt-xl-5,
    .login-page .my-xl-5 {
      margin-top: 3rem !important; }
    .login-page .mr-xl-5,
    .login-page .mx-xl-5 {
      margin-right: 3rem !important; }
    .login-page .mb-xl-5,
    .login-page .my-xl-5 {
      margin-bottom: 3rem !important; }
    .login-page .mx-xl-5 {
      margin-left: 3rem !important; }
    .login-page .p-xl-0 {
      padding: 0 !important; }
    .login-page .pt-xl-0 {
      padding-top: 0 !important; }
    .login-page .pb-xl-0 {
      padding-bottom: 0 !important; }
    .login-page .p-xl-1 {
      padding: 0.25rem !important; }
    .login-page .pt-xl-1 {
      padding-top: 0.25rem !important; }
    .login-page .pb-xl-1 {
      padding-bottom: 0.25rem !important; }
    .login-page .p-xl-2 {
      padding: 0.5rem !important; }
    .login-page .pt-xl-2 {
      padding-top: 0.5rem !important; }
    .login-page .pb-xl-2 {
      padding-bottom: 0.5rem !important; }
    .login-page .p-xl-3 {
      padding: 1rem !important; }
    .login-page .pt-xl-3 {
      padding-top: 1rem !important; }
    .login-page .pb-xl-3 {
      padding-bottom: 1rem !important; }
    .login-page .p-xl-4 {
      padding: 1.5rem !important; }
    .login-page .pt-xl-4 {
      padding-top: 1.5rem !important; }
    .login-page .pb-xl-4 {
      padding-bottom: 1.5rem !important; }
    .login-page .p-xl-5 {
      padding: 3rem !important; }
    .login-page .pt-xl-5 {
      padding-top: 3rem !important; }
    .login-page .pb-xl-5 {
      padding-bottom: 3rem !important; }
    .login-page .m-xl-n1 {
      margin: -0.25rem !important; }
    .login-page .mt-xl-n1,
    .login-page .my-xl-n1 {
      margin-top: -0.25rem !important; }
    .login-page .mr-xl-n1,
    .login-page .mx-xl-n1 {
      margin-right: -0.25rem !important; }
    .login-page .mb-xl-n1,
    .login-page .my-xl-n1 {
      margin-bottom: -0.25rem !important; }
    .login-page .mx-xl-n1 {
      margin-left: -0.25rem !important; }
    .login-page .m-xl-n2 {
      margin: -0.5rem !important; }
    .login-page .mt-xl-n2,
    .login-page .my-xl-n2 {
      margin-top: -0.5rem !important; }
    .login-page .mr-xl-n2,
    .login-page .mx-xl-n2 {
      margin-right: -0.5rem !important; }
    .login-page .mb-xl-n2,
    .login-page .my-xl-n2 {
      margin-bottom: -0.5rem !important; }
    .login-page .mx-xl-n2 {
      margin-left: -0.5rem !important; }
    .login-page .m-xl-n3 {
      margin: -1rem !important; }
    .login-page .mt-xl-n3,
    .login-page .my-xl-n3 {
      margin-top: -1rem !important; }
    .login-page .mr-xl-n3,
    .login-page .mx-xl-n3 {
      margin-right: -1rem !important; }
    .login-page .mb-xl-n3,
    .login-page .my-xl-n3 {
      margin-bottom: -1rem !important; }
    .login-page .mx-xl-n3 {
      margin-left: -1rem !important; }
    .login-page .m-xl-n4 {
      margin: -1.5rem !important; }
    .login-page .mt-xl-n4,
    .login-page .my-xl-n4 {
      margin-top: -1.5rem !important; }
    .login-page .mr-xl-n4,
    .login-page .mx-xl-n4 {
      margin-right: -1.5rem !important; }
    .login-page .mb-xl-n4,
    .login-page .my-xl-n4 {
      margin-bottom: -1.5rem !important; }
    .login-page .mx-xl-n4 {
      margin-left: -1.5rem !important; }
    .login-page .m-xl-n5 {
      margin: -3rem !important; }
    .login-page .mt-xl-n5,
    .login-page .my-xl-n5 {
      margin-top: -3rem !important; }
    .login-page .mr-xl-n5,
    .login-page .mx-xl-n5 {
      margin-right: -3rem !important; }
    .login-page .mb-xl-n5,
    .login-page .my-xl-n5 {
      margin-bottom: -3rem !important; }
    .login-page .mx-xl-n5 {
      margin-left: -3rem !important; }
    .login-page .m-xl-auto {
      margin: auto !important; }
    .login-page .mt-xl-auto,
    .login-page .my-xl-auto {
      margin-top: auto !important; }
    .login-page .mr-xl-auto,
    .login-page .mx-xl-auto {
      margin-right: auto !important; }
    .login-page .mb-xl-auto,
    .login-page .my-xl-auto {
      margin-bottom: auto !important; }
    .login-page .mx-xl-auto {
      margin-left: auto !important; } }
  .login-page .text-left {
    text-align: left !important; }
  .login-page .text-right {
    text-align: right !important; }
  .login-page .text-center {
    text-align: center !important; }
  @media (min-width: 768px) {
    .login-page .text-md-left {
      text-align: left !important; }
    .login-page .text-md-right {
      text-align: right !important; }
    .login-page .text-md-center {
      text-align: center !important; } }
  @media (min-width: 992px) {
    .login-page .text-lg-left {
      text-align: left !important; }
    .login-page .text-lg-right {
      text-align: right !important; }
    .login-page .text-lg-center {
      text-align: center !important; } }
  @media (min-width: 1200px) {
    .login-page .text-xl-left {
      text-align: left !important; }
    .login-page .text-xl-right {
      text-align: right !important; }
    .login-page .text-xl-center {
      text-align: center !important; } }
  .login-page .text-white {
    color: #fff !important; }
  .login-page .text-primary {
    color: #007bff !important; }
  .login-page a.text-primary:focus,
  .login-page a.text-primary:hover {
    color: #0056b3 !important; }
  .login-page .text-info {
    color: #17a2b8 !important; }
  .login-page a.text-info:focus,
  .login-page a.text-info:hover {
    color: #0f6674 !important; }
  .login-page .text-body {
    color: #515e79 !important; }
  .login-page .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }
  .login-page .text-reset {
    color: inherit !important; }
  .login-page .fade {
    -webkit-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .login-page .fade {
      -webkit-transition: none;
      transition: none; } }
  .login-page .fade:not(.show) {
    opacity: 0; }
  .login-page .modal-open {
    overflow: hidden; }
  .login-page .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }
  .login-page .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0; }
  .login-page .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
  .login-page .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
    -ms-transform: translate(0, -50px);
    transform: translate(0, -50px); }
  @media (prefers-reduced-motion: reduce) {
    .login-page .modal.fade .modal-dialog {
      -webkit-transition: none;
      transition: none; } }
  .login-page .modal.show .modal-dialog {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }
  .login-page .modal-dialog-centered {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: -webkit-calc(100% - 1rem);
    min-height: calc(100% - 1rem); }
  .login-page .modal-dialog-centered::before {
    display: block;
    height: -webkit-calc(100vh - 1rem);
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    content: ""; }
  .login-page .modal-content {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    -webkit-border-radius: 0.3rem;
    border-radius: 0.3rem;
    outline: 0; }
  .login-page .modal-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    -webkit-border-top-left-radius: -webkit-calc(0.3rem - 1px);
    border-top-left-radius: calc(0.3rem - 1px);
    -webkit-border-top-right-radius: -webkit-calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px); }
  .login-page .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }
  .login-page .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }
  .login-page .modal-body {
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem; }
  .login-page .modal-footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    -webkit-border-bottom-right-radius: -webkit-calc(0.3rem - 1px);
    border-bottom-right-radius: calc(0.3rem - 1px);
    -webkit-border-bottom-left-radius: -webkit-calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px); }
  .login-page .modal-footer > * {
    margin: 0.25rem; }
  @media (min-width: 576px) {
    .login-page .modal-dialog {
      max-width: 500px;
      margin: 1.75rem auto; }
    .login-page .modal-dialog-centered {
      min-height: -webkit-calc(100% - 3.5rem);
      min-height: calc(100% - 3.5rem); }
    .login-page .modal-dialog-centered::before {
      height: -webkit-calc(100vh - 3.5rem);
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: -moz-min-content;
      height: min-content; } }
  @media (min-width: 992px) {
    .login-page .modal-lg,
    .login-page .modal-xl {
      max-width: 800px; } }
  @media (min-width: 1200px) {
    .login-page .modal-xl {
      max-width: 1140px; } }
  .login-page .btn {
    background-color: transparent;
    outline: 0;
    color: #343a40;
    padding: 25px 35px;
    font-size: 15px;
    -webkit-border-radius: 50px;
    border-radius: 50px;
    font-weight: 500;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    -webkit-border-radius: 46px;
    border-radius: 46px; }
  .login-page .btn:active,
  .login-page .btn:focus,
  .login-page .btn:hover {
    text-decoration: none;
    border-color: transparent; }
  .login-page .btn-lg {
    padding: 35px 60px;
    letter-spacing: 1.53px;
    text-transform: uppercase;
    font-size: 14px; }
  .login-page .btn-block {
    display: block;
    width: 100%; }
  .login-page .btn-nav-signin {
    border: 1px solid rgba(6, 169, 255, 0.19);
    color: #00a7ff;
    letter-spacing: 0.6px;
    text-align: center; }
  .login-page .btn-nav-signin:active,
  .login-page .btn-nav-signin:focus,
  .login-page .btn-nav-signin:hover {
    background-color: #00a7ff;
    color: #fff; }
  .login-page .btn-nav-demo {
    background-image: linear-gradient(224deg, rgba(0, 215, 255, 0.08) 0, rgba(0, 174, 255, 0.08) 100%);
    -webkit-border-radius: 46px;
    border-radius: 46px;
    color: #00a7ff;
    border-color: transparent; }
  .login-page .btn-nav-demo:active,
  .login-page .btn-nav-demo:focus,
  .login-page .btn-nav-demo:hover {
    background-color: #00a7ff;
    color: #fff; }
  .login-page .btn-primary {
    background-color: #00a7ff;
    background-image: linear-gradient(224deg, #00a7ff 0, #47bfff 100%);
    -webkit-box-shadow: 0 43px 40px -17px rgba(7, 127, 190, 0.14);
    box-shadow: 0 43px 40px -17px rgba(7, 127, 190, 0.14);
    letter-spacing: 1.53px;
    font-weight: 700;
    font-size: 14px;
    color: #fff;
    letter-spacing: 1.53px;
    text-align: center; }
  .login-page .btn-primary:active,
  .login-page .btn-primary:focus,
  .login-page .btn-primary:hover {
    color: #fff;
    background-size: 200% 100%; }
  .login-page .mr-0 {
    margin-right: 0 !important; }
  .login-page .mr-auto {
    margin-right: auto !important; }
  .login-page .mx-auto {
    margin-left: auto;
    margin-right: auto; }
  .login-page .pt-10 {
    padding-top: 10px !important; }
  .login-page .pb-10 {
    padding-bottom: 10px !important; }
  .login-page .mt-10 {
    margin-top: 10px !important; }
  .login-page .mb-10 {
    margin-bottom: 10px !important; }
  .login-page .pt-15 {
    padding-top: 15px !important; }
  .login-page .pb-15 {
    padding-bottom: 15px !important; }
  .login-page .mt-15 {
    margin-top: 15px !important; }
  .login-page .mb-15 {
    margin-bottom: 15px !important; }
  .login-page .pt-20 {
    padding-top: 20px !important; }
  .login-page .pb-20 {
    padding-bottom: 20px !important; }
  .login-page .mt-20 {
    margin-top: 20px !important; }
  .login-page .mb-20 {
    margin-bottom: 20px !important; }
  .login-page .pt-25 {
    padding-top: 25px !important; }
  .login-page .pb-25 {
    padding-bottom: 25px !important; }
  .login-page .mt-25 {
    margin-top: 25px !important; }
  .login-page .mb-25 {
    margin-bottom: 25px !important; }
  .login-page .pt-30 {
    padding-top: 30px !important; }
  .login-page .pb-30 {
    padding-bottom: 30px !important; }
  .login-page .mt-30 {
    margin-top: 30px !important; }
  .login-page .mb-30 {
    margin-bottom: 30px !important; }
  .login-page .pt-35 {
    padding-top: 35px !important; }
  .login-page .pb-35 {
    padding-bottom: 35px !important; }
  .login-page .mt-35 {
    margin-top: 35px !important; }
  .login-page .mb-35 {
    margin-bottom: 35px !important; }
  .login-page .pt-40 {
    padding-top: 40px !important; }
  .login-page .pb-40 {
    padding-bottom: 40px !important; }
  .login-page .mt-40 {
    margin-top: 40px !important; }
  .login-page .mb-40 {
    margin-bottom: 40px !important; }
  .login-page .pt-45 {
    padding-top: 45px !important; }
  .login-page .pb-45 {
    padding-bottom: 45px !important; }
  .login-page .mt-45 {
    margin-top: 45px !important; }
  .login-page .mb-45 {
    margin-bottom: 45px !important; }
  .login-page .pt-50 {
    padding-top: 50px !important; }
  .login-page .pb-50 {
    padding-bottom: 50px !important; }
  .login-page .mt-50 {
    margin-top: 50px !important; }
  .login-page .mb-50 {
    margin-bottom: 50px !important; }
  .login-page .pt-55 {
    padding-top: 55px !important; }
  .login-page .pb-55 {
    padding-bottom: 55px !important; }
  .login-page .mt-55 {
    margin-top: 55px !important; }
  .login-page .mb-55 {
    margin-bottom: 55px !important; }
  .login-page .pt-60 {
    padding-top: 60px !important; }
  .login-page .pb-60 {
    padding-bottom: 60px !important; }
  .login-page .mt-60 {
    margin-top: 60px !important; }
  .login-page .mb-60 {
    margin-bottom: 60px !important; }
  .login-page .pt-65 {
    padding-top: 65px !important; }
  .login-page .pb-65 {
    padding-bottom: 65px !important; }
  .login-page .mt-65 {
    margin-top: 65px !important; }
  .login-page .mb-65 {
    margin-bottom: 65px !important; }
  .login-page .pt-70 {
    padding-top: 70px !important; }
  .login-page .pb-70 {
    padding-bottom: 70px !important; }
  .login-page .mt-70 {
    margin-top: 70px !important; }
  .login-page .mb-70 {
    margin-bottom: 70px !important; }
  .login-page .pt-75 {
    padding-top: 75px !important; }
  .login-page .pb-75 {
    padding-bottom: 75px !important; }
  .login-page .mt-75 {
    margin-top: 75px !important; }
  .login-page .mb-75 {
    margin-bottom: 75px !important; }
  .login-page .pt-80 {
    padding-top: 80px !important; }
  .login-page .pb-80 {
    padding-bottom: 80px !important; }
  .login-page .mt-80 {
    margin-top: 80px !important; }
  .login-page .mb-80 {
    margin-bottom: 80px !important; }
  .login-page .pt-85 {
    padding-top: 85px !important; }
  .login-page .pb-85 {
    padding-bottom: 85px !important; }
  .login-page .mt-85 {
    margin-top: 85px !important; }
  .login-page .mb-85 {
    margin-bottom: 85px !important; }
  .login-page .pt-90 {
    padding-top: 90px !important; }
  .login-page .pb-90 {
    padding-bottom: 90px !important; }
  .login-page .mt-90 {
    margin-top: 90px !important; }
  .login-page .mb-90 {
    margin-bottom: 90px !important; }
  .login-page .pt-95 {
    padding-top: 95px !important; }
  .login-page .pb-95 {
    padding-bottom: 95px !important; }
  .login-page .mt-95 {
    margin-top: 95px !important; }
  .login-page .mb-95 {
    margin-bottom: 95px !important; }
  .login-page .pt-100 {
    padding-top: 100px !important; }
  .login-page .pb-100 {
    padding-bottom: 100px !important; }
  .login-page .mt-100 {
    margin-top: 100px !important; }
  .login-page .mb-100 {
    margin-bottom: 100px !important; }
  .login-page .pt-105 {
    padding-top: 105px !important; }
  .login-page .pb-105 {
    padding-bottom: 105px !important; }
  .login-page .mt-105 {
    margin-top: 105px !important; }
  .login-page .mb-105 {
    margin-bottom: 105px !important; }
  .login-page .pt-110 {
    padding-top: 110px !important; }
  .login-page .pb-110 {
    padding-bottom: 110px !important; }
  .login-page .mt-110 {
    margin-top: 110px !important; }
  .login-page .mb-110 {
    margin-bottom: 110px !important; }
  .login-page .pt-115 {
    padding-top: 115px !important; }
  .login-page .pb-115 {
    padding-bottom: 115px !important; }
  .login-page .mt-115 {
    margin-top: 115px !important; }
  .login-page .mb-115 {
    margin-bottom: 115px !important; }
  .login-page .pt-120 {
    padding-top: 120px !important; }
  .login-page .pb-120 {
    padding-bottom: 120px !important; }
  .login-page .mt-120 {
    margin-top: 120px !important; }
  .login-page .mb-120 {
    margin-bottom: 120px !important; }
  .login-page .pt-125 {
    padding-top: 125px !important; }
  .login-page .pb-125 {
    padding-bottom: 125px !important; }
  .login-page .mt-125 {
    margin-top: 125px !important; }
  .login-page .mb-125 {
    margin-bottom: 125px !important; }
  .login-page .pt-130 {
    padding-top: 130px !important; }
  .login-page .pb-130 {
    padding-bottom: 130px !important; }
  .login-page .mt-130 {
    margin-top: 130px !important; }
  .login-page .mb-130 {
    margin-bottom: 130px !important; }
  .login-page .pt-135 {
    padding-top: 135px !important; }
  .login-page .pb-135 {
    padding-bottom: 135px !important; }
  .login-page .mt-135 {
    margin-top: 135px !important; }
  .login-page .mb-135 {
    margin-bottom: 135px !important; }
  .login-page .pt-140 {
    padding-top: 140px !important; }
  .login-page .pb-140 {
    padding-bottom: 140px !important; }
  .login-page .mt-140 {
    margin-top: 140px !important; }
  .login-page .mb-140 {
    margin-bottom: 140px !important; }
  .login-page .pt-145 {
    padding-top: 145px !important; }
  .login-page .pb-145 {
    padding-bottom: 145px !important; }
  .login-page .mt-145 {
    margin-top: 145px !important; }
  .login-page .mb-145 {
    margin-bottom: 145px !important; }
  .login-page .pt-150 {
    padding-top: 150px !important; }
  .login-page .pb-150 {
    padding-bottom: 150px !important; }
  .login-page .mt-150 {
    margin-top: 150px !important; }
  .login-page .mb-150 {
    margin-bottom: 150px !important; }
  .login-page .pt-155 {
    padding-top: 155px !important; }
  .login-page .pb-155 {
    padding-bottom: 155px !important; }
  .login-page .mt-155 {
    margin-top: 155px !important; }
  .login-page .mb-155 {
    margin-bottom: 155px !important; }
  .login-page .pt-160 {
    padding-top: 160px !important; }
  .login-page .pb-160 {
    padding-bottom: 160px !important; }
  .login-page .mt-160 {
    margin-top: 160px !important; }
  .login-page .mb-160 {
    margin-bottom: 160px !important; }
  .login-page .pt-165 {
    padding-top: 165px !important; }
  .login-page .pb-165 {
    padding-bottom: 165px !important; }
  .login-page .mt-165 {
    margin-top: 165px !important; }
  .login-page .mb-165 {
    margin-bottom: 165px !important; }
  .login-page .pt-170 {
    padding-top: 170px !important; }
  .login-page .pb-170 {
    padding-bottom: 170px !important; }
  .login-page .mt-170 {
    margin-top: 170px !important; }
  .login-page .mb-170 {
    margin-bottom: 170px !important; }
  .login-page .pt-175 {
    padding-top: 175px !important; }
  .login-page .pb-175 {
    padding-bottom: 175px !important; }
  .login-page .mt-175 {
    margin-top: 175px !important; }
  .login-page .mb-175 {
    margin-bottom: 175px !important; }
  .login-page .pt-180 {
    padding-top: 180px !important; }
  .login-page .pb-180 {
    padding-bottom: 180px !important; }
  .login-page .mt-180 {
    margin-top: 180px !important; }
  .login-page .mb-180 {
    margin-bottom: 180px !important; }
  .login-page .pt-185 {
    padding-top: 185px !important; }
  .login-page .pb-185 {
    padding-bottom: 185px !important; }
  .login-page .mt-185 {
    margin-top: 185px !important; }
  .login-page .mb-185 {
    margin-bottom: 185px !important; }
  .login-page .pt-190 {
    padding-top: 190px !important; }
  .login-page .pb-190 {
    padding-bottom: 190px !important; }
  .login-page .mt-190 {
    margin-top: 190px !important; }
  .login-page .mb-190 {
    margin-bottom: 190px !important; }
  .login-page .pt-195 {
    padding-top: 195px !important; }
  .login-page .pb-195 {
    padding-bottom: 195px !important; }
  .login-page .mt-195 {
    margin-top: 195px !important; }
  .login-page .mb-195 {
    margin-bottom: 195px !important; }
  .login-page .pt-200 {
    padding-top: 200px !important; }
  .login-page .pb-200 {
    padding-bottom: 200px !important; }
  .login-page .mt-200 {
    margin-top: 200px !important; }
  .login-page .mb-200 {
    margin-bottom: 200px !important; }
  .login-page .pt-205 {
    padding-top: 205px !important; }
  .login-page .pb-205 {
    padding-bottom: 205px !important; }
  .login-page .mt-205 {
    margin-top: 205px !important; }
  .login-page .mb-205 {
    margin-bottom: 205px !important; }
  .login-page .pt-210 {
    padding-top: 210px !important; }
  .login-page .pb-210 {
    padding-bottom: 210px !important; }
  .login-page .mt-210 {
    margin-top: 210px !important; }
  .login-page .mb-210 {
    margin-bottom: 210px !important; }
  .login-page .pt-215 {
    padding-top: 215px !important; }
  .login-page .pb-215 {
    padding-bottom: 215px !important; }
  .login-page .mt-215 {
    margin-top: 215px !important; }
  .login-page .mb-215 {
    margin-bottom: 215px !important; }
  .login-page .pt-220 {
    padding-top: 220px !important; }
  .login-page .pb-220 {
    padding-bottom: 220px !important; }
  .login-page .mt-220 {
    margin-top: 220px !important; }
  .login-page .mb-220 {
    margin-bottom: 220px !important; }
  .login-page .pt-225 {
    padding-top: 225px !important; }
  .login-page .pb-225 {
    padding-bottom: 225px !important; }
  .login-page .mt-225 {
    margin-top: 225px !important; }
  .login-page .mb-225 {
    margin-bottom: 225px !important; }
  .login-page .pt-230 {
    padding-top: 230px !important; }
  .login-page .pb-230 {
    padding-bottom: 230px !important; }
  .login-page .mt-230 {
    margin-top: 230px !important; }
  .login-page .mb-230 {
    margin-bottom: 230px !important; }
  .login-page .pt-235 {
    padding-top: 235px !important; }
  .login-page .pb-235 {
    padding-bottom: 235px !important; }
  .login-page .mt-235 {
    margin-top: 235px !important; }
  .login-page .mb-235 {
    margin-bottom: 235px !important; }
  .login-page .pt-240 {
    padding-top: 240px !important; }
  .login-page .pb-240 {
    padding-bottom: 240px !important; }
  .login-page .mt-240 {
    margin-top: 240px !important; }
  .login-page .mb-240 {
    margin-bottom: 240px !important; }
  .login-page .pt-245 {
    padding-top: 245px !important; }
  .login-page .pb-245 {
    padding-bottom: 245px !important; }
  .login-page .mt-245 {
    margin-top: 245px !important; }
  .login-page .mb-245 {
    margin-bottom: 245px !important; }
  .login-page .pt-250 {
    padding-top: 250px !important; }
  .login-page .pb-250 {
    padding-bottom: 250px !important; }
  .login-page .mt-250 {
    margin-top: 250px !important; }
  .login-page .mb-250 {
    margin-bottom: 250px !important; }
  .login-page .pt-255 {
    padding-top: 255px !important; }
  .login-page .pb-255 {
    padding-bottom: 255px !important; }
  .login-page .mt-255 {
    margin-top: 255px !important; }
  .login-page .mb-255 {
    margin-bottom: 255px !important; }
  .login-page .pt-260 {
    padding-top: 260px !important; }
  .login-page .pb-260 {
    padding-bottom: 260px !important; }
  .login-page .mt-260 {
    margin-top: 260px !important; }
  .login-page .mb-260 {
    margin-bottom: 260px !important; }
  .login-page .pt-265 {
    padding-top: 265px !important; }
  .login-page .pb-265 {
    padding-bottom: 265px !important; }
  .login-page .mt-265 {
    margin-top: 265px !important; }
  .login-page .mb-265 {
    margin-bottom: 265px !important; }
  .login-page .pt-270 {
    padding-top: 270px !important; }
  .login-page .pb-270 {
    padding-bottom: 270px !important; }
  .login-page .mt-270 {
    margin-top: 270px !important; }
  .login-page .mb-270 {
    margin-bottom: 270px !important; }
  .login-page .pt-275 {
    padding-top: 275px !important; }
  .login-page .pb-275 {
    padding-bottom: 275px !important; }
  .login-page .mt-275 {
    margin-top: 275px !important; }
  .login-page .mb-275 {
    margin-bottom: 275px !important; }
  .login-page .pt-280 {
    padding-top: 280px !important; }
  .login-page .pb-280 {
    padding-bottom: 280px !important; }
  .login-page .mt-280 {
    margin-top: 280px !important; }
  .login-page .mb-280 {
    margin-bottom: 280px !important; }
  .login-page .pt-285 {
    padding-top: 285px !important; }
  .login-page .pb-285 {
    padding-bottom: 285px !important; }
  .login-page .mt-285 {
    margin-top: 285px !important; }
  .login-page .mb-285 {
    margin-bottom: 285px !important; }

@-webkit-keyframes stickySlideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes stickySlideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%); }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }
  .login-page .menu-open__logo {
    display: none; }
  .login-page .navbar-main {
    padding-top: 15px;
    padding-bottom: 15px; }
  .login-page .navbar-main.stick-to-top {
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.07);
    margin-top: 0;
    padding-top: 15px;
    padding-bottom: 15px; }
  .login-page .navbar-main .navbar-toggler__close {
    display: none; }
  .login-page .navbar-main .btn-nav-signin {
    margin-right: 1rem; }
  .login-page .navbar-main .navbar-brand {
    position: relative; }
  .login-page .navbar-main .white-logo {
    opacity: 1;
    visibility: visible; }
  .login-page .navbar-main .navbar-nav .nav-link {
    font-weight: 500;
    color: #515d7a;
    letter-spacing: 0.2px;
    text-decoration: none;
    position: relative;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px; }
  .login-page .navbar-main .nav-item {
    position: relative;
    height: 50px;
    margin: 0 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    height: 80px;
    z-index: 1; }
  .login-page .navbar-main .nav-item:hover::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 90%;
    height: 3px;
    background-color: #00a7ff;
    margin: 0 auto; }
  .login-page .navbar-main.header-demo .navbar-nav .nav-link:hover::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -7px;
    width: 100%;
    height: 2px;
    background-color: #343a40;
    margin: 0 auto; }
  .login-page .navbar-text-white .navbar-nav .nav-link {
    color: #fff; }
  .login-page .dropdown {
    position: relative; }
  .login-page .dropdown::after {
    border-style: solid;
    border-width: 1.5px 1.5px 0 0;
    content: "";
    display: inline-block;
    height: 0.45em;
    position: relative;
    top: 0;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
    vertical-align: top;
    width: 0.45em;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%) rotate(135deg);
    -ms-transform: translateY(-50%) rotate(135deg);
    transform: translateY(-50%) rotate(135deg);
    right: -8px; }
  .login-page .dropdown:hover .dropdown__content {
    display: block; }
  .login-page .dropdown__content {
    display: none;
    position: absolute;
    z-index: 1;
    background: #fff;
    -webkit-box-shadow: 0 41px 61px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 41px 61px 0 rgba(0, 0, 0, 0.1);
    -webkit-border-radius: 15px 100px 27px 100px 15px 15px 15px;
    border-radius: 15px 100px 27px 100px 15px 15px 15px;
    width: 290px;
    padding: 20px;
    -webkit-border-radius: 15px;
    border-radius: 15px;
    top: 90px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .login-page .dropdown__content::after {
    content: " ";
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%) rotate(225deg);
    -ms-transform: translateX(-50%) rotate(225deg);
    transform: translateX(-50%) rotate(225deg);
    top: -15px;
    width: 30px;
    height: 30px;
    -webkit-border-radius: 0 0 10px 0;
    border-radius: 0 0 10px 0;
    background: linear-gradient(-45deg, #fff 50%, transparent 50%); }
  .login-page .dropdown__content a {
    color: #292e37;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    -webkit-border-radius: 31px;
    border-radius: 31px;
    padding: 20px 30px; }
  .login-page .dropdown__content a:hover {
    background-color: rgba(0, 167, 255, 0.1);
    color: #00a7ff; }
  .login-page .navbar-toggler {
    background: #def4fe;
    height: 42px;
    width: 42px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
  .login-page .navbar-toggler:focus {
    outline: 0; }
  .login-page .navbar-toggler svg {
    height: 8px;
    color: #00a7ff; }
  @media (max-width: 992px) {
    .login-page .navbar-main {
      transition: all 0.4s ease; }
    .login-page .navbar-main .navbar-collapse {
      margin-top: 60px; }
    .login-page .navbar-main .navbar-brand img {
      width: 121px; }
    .login-page .navbar-main .navbar-nav .nav-link {
      font-size: 20px;
      color: #fff;
      padding: 0;
      transition: all 0.4s ease; }
    .login-page .navbar-main .nav-item {
      height: auto;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: start;
      -webkit-align-items: flex-start;
      -ms-flex-align: start;
      align-items: flex-start;
      margin-bottom: 40px; }
    .login-page .navbar-main .nav-item:hover::before {
      width: 100px; }
    .login-page .navbar-main .nav-right .btn {
      margin-bottom: 10px; }
    .login-page .dropdown {
      position: relative; }
    .login-page .dropdown::after {
      top: 15px;
      color: #fff; }
    .login-page .dropdown__content {
      position: relative;
      top: auto;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      left: auto;
      width: auto;
      background-color: transparent;
      -webkit-box-shadow: none;
      box-shadow: none;
      padding: 0; }
    .login-page .dropdown__content::after {
      display: none; }
    .login-page .dropdown__content a {
      color: #fff;
      font-size: 14px;
      color: #fff;
      letter-spacing: 0.5px;
      line-height: 40px;
      padding: 0; }
    .login-page .dropdown__content a:hover {
      color: #fff; }
    .login-page .menu-open .navbar-toggler__close {
      display: block; }
    .login-page .menu-open .navbar-toggler__open {
      display: none; }
    .login-page .menu-open .menu-open__hidelogo {
      display: none; }
    .login-page .menu-open .menu-open__logo {
      display: block; }
    .login-page .menu-open .navbar-main {
      background-color: #00a7ff; }
    .login-page .menu-open .navbar-main .navbar-nav .nav-link {
      opacity: 1; }
    .login-page .menu-open .btn {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 1.31px;
      text-align: center; }
    .login-page .menu-open .btn-nav-signin {
      border: 1px solid #fff;
      background-color: transparent;
      -webkit-border-radius: 46px;
      border-radius: 46px;
      color: #fff;
      margin: 0; }
    .login-page .menu-open .btn-nav-demo {
      border: 1px solid #fff;
      background-color: #fff;
      -webkit-border-radius: 46px;
      border-radius: 46px;
      color: #00a7ff; } }
  .login-page img {
    max-width: 100%; }
  .login-page a:hover {
    text-decoration: none; }
  .login-page .reset-ul {
    padding: 0;
    margin: 0;
    list-style: none; }
  .login-page .bg-no-repeat {
    background-repeat: no-repeat; }
  .login-page .swaton-circular {
    font-family: "Sawton Circular", sans-serif; }
  .login-page .section-bg {
    margin-top: 190px; }
  .login-page .section-title {
    font-weight: 500;
    font-size: 125px;
    color: #2a2e36;
    letter-spacing: -6px;
    line-height: 119px; }
  .login-page .section-title span {
    display: inline-block;
    position: relative; }
  .login-page .section-title span::after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 46px;
    background: #bbf2ff;
    bottom: -20px;
    z-index: -1; }
  .login-page .section-description {
    font-size: 24px;
    color: #515e79;
    letter-spacing: 0.19px;
    line-height: 41px; }
  .login-page .card {
    background: #fff;
    -webkit-box-shadow: 30px 106px 127px -24px rgba(0, 0, 0, 0.05);
    box-shadow: 30px 106px 127px -24px rgba(0, 0, 0, 0.05);
    -webkit-border-radius: 59px 32px 32px 59px;
    border-radius: 59px 32px 32px 59px;
    padding: 70px; }
  @media (max-width: 992px) {
    .login-page #home {
      overflow-x: hidden;
      background-image: none; }
    .login-page .section-bg {
      background-image: none; }
    .login-page .section-title {
      font-size: 50px;
      letter-spacing: -2.4px;
      line-height: 42px;
      text-align: center; }
    .login-page .section-title span::after {
      height: 24px;
      bottom: -20px; }
    .login-page .section-description {
      font-size: 14px;
      letter-spacing: 0.11px;
      text-align: center;
      line-height: 24px; } }
  .login-page .whyportpro {
    padding-top: 150px;
    padding-bottom: 150px; }
  .login-page .whyportpro .section-title {
    margin-bottom: 110px; }
  .login-page .whyportpro__col {
    text-align: center; }
  .login-page .whyportpro__col:first-child {
    padding-right: 70px; }
  .login-page .whyportpro__col:nth-child(2) {
    padding-right: 35px;
    padding-left: 35px; }
  .login-page .whyportpro__col:last-child {
    padding-left: 70px; }
  .login-page .whyportpro__icon {
    width: 135px;
    height: 135px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #00a7ff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto; }
  .login-page .whyportpro__icon svg {
    height: 45px;
    color: #fff; }
  .login-page .whyportpro__icon-1 {
    background-color: #00d9ff; }
  .login-page .whyportpro__icon-2 {
    background-color: #0095ff; }
  .login-page .whyportpro__icon-3 {
    background-color: #001caf; }
  .login-page .whyportpro__title {
    font-family: "Sawton Circular", sans-serif;
    font-size: 50px;
    color: #292e37;
    letter-spacing: -2.33px;
    line-height: 60px;
    margin-bottom: 30px; }
  .login-page .whyportpro__description {
    font-size: 20px;
    color: #545f76;
    letter-spacing: 0.07px;
    line-height: 34px; }
  @media (max-width: 992px) {
    .login-page .whyportpro {
      padding-top: 85px;
      padding-bottom: 65px; }
    .login-page .whyportpro .section-title {
      margin-bottom: 60px; }
    .login-page .whyportpro__col {
      text-align: center;
      margin-bottom: 60px;
      padding-left: 15px;
      padding-right: 15px; }
    .login-page .whyportpro__col:first-child {
      padding-right: 15px; }
    .login-page .whyportpro__col:nth-child(2) {
      padding-right: 15px;
      padding-left: 15px; }
    .login-page .whyportpro__col:last-child {
      padding-left: 15px; }
    .login-page .whyportpro__icon {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
      width: 90px;
      height: 90px; }
    .login-page .whyportpro__icon svg {
      height: 25px; }
    .login-page .whyportpro__title {
      font-size: 30px;
      letter-spacing: -1.4px;
      text-align: center;
      line-height: 37px;
      margin-bottom: 15px; }
    .login-page .whyportpro__description {
      font-size: 14px;
      letter-spacing: 0.05px;
      text-align: center;
      line-height: 22px; } }
  .login-page .features {
    padding-bottom: 150px; }
  .login-page .features--1 {
    padding-top: 150px; }
  .login-page .features .section-title {
    margin-bottom: 90px; }
  .login-page .features__row {
    padding: 100px 0; }
  .login-page .features__icon {
    width: 98px;
    height: 98px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #00a7ff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 24px; }
  .login-page .features__icon svg {
    height: 32px;
    color: #fff; }
  .login-page .features__icon-1 {
    background: #00d9ff;
    -webkit-box-shadow: 0 15px 60px -10px rgba(0, 217, 255, 0.61);
    box-shadow: 0 15px 60px -10px rgba(0, 217, 255, 0.61);
    -webkit-border-radius: 83px;
    border-radius: 83px; }
  .login-page .features__icon-2 {
    background: #0068ff;
    -webkit-box-shadow: 0 33px 82px -25px rgba(0, 217, 255, 0.61);
    box-shadow: 0 33px 82px -25px rgba(0, 217, 255, 0.61);
    -webkit-border-radius: 83px;
    border-radius: 83px; }
  .login-page .features__icon-3 {
    background: #001caf;
    -webkit-box-shadow: 0 15px 60px -10px rgba(0, 28, 175, 0.35);
    box-shadow: 0 15px 60px -10px rgba(0, 28, 175, 0.35);
    -webkit-border-radius: 83px;
    border-radius: 83px; }
  .login-page .features__icon-4 {
    background: #00d9ff;
    -webkit-box-shadow: 0 15px 60px -10px rgba(0, 28, 175, 0.35);
    box-shadow: 0 15px 60px -10px rgba(0, 28, 175, 0.35);
    -webkit-border-radius: 83px;
    border-radius: 83px; }
  .login-page .features__icon-5 {
    background: #0068ff;
    -webkit-box-shadow: 0 15px 60px -10px rgba(0, 104, 255, 0.44);
    box-shadow: 0 15px 60px -10px rgba(0, 104, 255, 0.44);
    -webkit-border-radius: 83px;
    border-radius: 83px; }
  .login-page .features__icon-6 {
    background: #001caf;
    -webkit-box-shadow: 0 15px 60px -10px rgba(0, 28, 175, 0.35);
    box-shadow: 0 15px 60px -10px rgba(0, 28, 175, 0.35);
    -webkit-border-radius: 83px;
    border-radius: 83px; }
  .login-page .features__title {
    font-family: "Sawton Circular", sans-serif;
    font-size: 65px;
    color: #292e37;
    letter-spacing: -3.03px;
    line-height: 77px;
    margin-bottom: 30px;
    text-align: left;
    font-weight: 500; }
  .login-page .features__description {
    font-size: 20px;
    color: #545f76;
    letter-spacing: 0.05px;
    line-height: 30px;
    margin-bottom: 30px; }
  .login-page .features__image {
    width: 100%; }
  @media (min-width: 992px) {
    .login-page .features__section {
      overflow-x: hidden; } }
  @media (max-width: 992px) {
    .login-page .features {
      padding-bottom: 55px; }
    .login-page .features--1 {
      padding-top: 75px; }
    .login-page .features .section-title {
      margin-bottom: 0; }
    .login-page .features__section .features__row {
      padding: 70px 0; }
    .login-page .features__section:nth-child(odd) .features__row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -webkit-flex-direction: column-reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse; }
    .login-page .features__section:last-child .features__row {
      padding-bottom: 0; }
    .login-page .features__col img {
      margin-top: 16px; }
    .login-page .features__title {
      font-size: 36px;
      letter-spacing: -1.68px;
      line-height: 42px; }
    .login-page .features__description {
      font-size: 14px;
      letter-spacing: 0.05px;
      line-height: 21px; }
    .login-page .features ul {
      font-size: 14px;
      letter-spacing: 0.05px;
      line-height: 21px; } }
  .login-page .main-footer .widget-title {
    font-size: 20px;
    color: #778293;
    letter-spacing: 0;
    line-height: 60px; }
  .login-page .main-footer ul li a {
    font-size: 16px;
    color: #545f76;
    letter-spacing: 0.05px;
    line-height: 35px; }
  .login-page .main-footer__right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end; }
  .login-page .main-footer__contact {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -webkit-align-items: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin-bottom: 30px; }
  .login-page .main-footer__contact a {
    color: #545f76; }
  .login-page .main-footer__contact svg {
    width: 15px;
    margin-right: 10px; }
  .login-page .social-icon a {
    color: #545f76; }
  .login-page .social-icon a svg {
    height: 20px;
    color: #545f76;
    margin-right: 50px; }
  @media (max-width: 992px) {
    .login-page .main-footer__right {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
      -ms-flex-pack: start;
      justify-content: flex-start; }
    .login-page .main-footer__col {
      margin-bottom: 40px; }
    .login-page .main-footer__col:first-child {
      -webkit-box-ordinal-group: 4;
      -webkit-order: 3;
      -ms-flex-order: 3;
      order: 3;
      margin-top: 100px;
      text-align: center; } }
  .login-page .form-group {
    margin-bottom: 15px; }
  .login-page input,
  .login-page textarea {
    background: #fff;
    border: 1px solid #d8e0eb;
    -webkit-border-radius: 18px;
    border-radius: 18px;
    min-height: 86px;
    width: 100%;
    padding: 30px 35px;
    font-size: 20px;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out; }
  .login-page input::-webkit-input-placeholder,
  .login-page textarea::-webkit-input-placeholder {
    font-size: 20px;
    color: #9ba6b7;
    letter-spacing: -0.26px; }
  .login-page input:-ms-input-placeholder,
  .login-page textarea:-ms-input-placeholder {
    font-size: 20px;
    color: #9ba6b7;
    letter-spacing: -0.26px; }
  .login-page input::placeholder,
  .login-page textarea::placeholder {
    font-size: 20px;
    color: #9ba6b7;
    letter-spacing: -0.26px; }
  .login-page input:focus,
  .login-page textarea:focus {
    outline: 0;
    border: 1px solid #00a7ff; }
  .login-page .signin .card {
    padding: 0; }
  .login-page .signin .tab-content {
    padding-left: 70px;
    padding-right: 70px;
    padding-bottom: 70px;
    margin-top: 60px;
    border-radius: 59px 32px 32px 59px; }
  .login-page .signin .nav-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .login-page .signin .nav-item {
    width: 50%;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 40px;
    font-size: 16px;
    color: #9ca6b6;
    letter-spacing: 0.13px;
    position: relative; }
  .login-page .signin .nav-item svg {
    height: 20px;
    margin-right: 13px; }
  .login-page .signin .nav-item:hover {
    color: #08aaff; }
  .login-page .signin .nav-item.active {
    font-size: 16px;
    color: #08aaff;
    letter-spacing: 0.13px; }
  .login-page .signin .nav-item.active::after {
    content: "";
    position: absolute;
    background: #08aaff;
    height: 6px;
    width: 100%;
    bottom: 0;
    left: 0; }
  @media (max-width: 992px) {
    .login-page .request-demo .card {
      margin-top: 50px;
      padding: 20px; }
    .login-page .signin .tab-content {
      padding: 20px;
      margin-top: 30px; } }
  .login-page .support {
    padding-bottom: 200px; }
  .login-page .support__col {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .login-page .support__icon {
    min-width: 125px;
    width: 125px;
    height: 125px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #00a7ff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 50px;
    margin-right: 30px; }
  .login-page .support__icon svg {
    height: 45px;
    color: #fff; }
  .login-page .support__icon-1 {
    background-color: #00d9ff; }
  .login-page .support__icon-2 {
    background-color: #001caf; }
  .login-page .support__title {
    font-size: 50px;
    color: #2a2d37;
    letter-spacing: -2.33px;
    line-height: 55px;
    margin-bottom: 15px; }
  .login-page .support__description {
    font-family: "Sawton Circular", sans-serif;
    font-size: 20px;
    color: #525e78;
    letter-spacing: 0.07px;
    line-height: 34px; }
  @media (max-width: 992px) {
    .login-page .support {
      padding-bottom: 0; }
    .login-page .support__col {
      margin-bottom: 50px; }
    .login-page .support__icon {
      min-width: 90px;
      width: 90px;
      height: 90px;
      margin-right: 20px; }
    .login-page .support__icon svg {
      height: 24px; }
    .login-page .support__title {
      font-size: 30px;
      letter-spacing: -1.4px;
      line-height: 37px; }
    .login-page .support__description {
      font-size: 14px;
      letter-spacing: 0.05px;
      line-height: 22px; } }
  .login-page .markets {
    padding-top: 150px;
    padding-bottom: 150px; }
  .login-page .markets .section-title {
    margin-bottom: 190px; }
  @media (max-width: 992px) {
    .login-page .markets {
      padding-top: 75px;
      padding-bottom: 70px; }
    .login-page .markets .section-title {
      margin-bottom: 45px; } }
  .login-page .press-content .press-content__title {
    font-size: 50px;
    color: #2a2d37;
    letter-spacing: -2.33px;
    line-height: 55px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: center; }
  .login-page .press-content .press-content__img {
    margin-bottom: 60px;
    text-align: center;
    margin-left: auto;
    margin-right: auto; }
  .login-page .press-content .press-content__img img {
    border-radius: 10px; }
  .login-page .source-link {
    margin-top: 30px; }
  .login-page .source-link a {
    display: block; }
  .login-page .source-link p {
    margin-bottom: 0; }
  .login-page .form-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
  .login-page .form-row > .col,
  .login-page .form-row > [class*="col-"] {
    padding-left: 5px;
    padding-right: 5px; }
  .login-page .css-1hwfws3 {
    padding-left: 35px; }
  .login-page .css-151xaom-placeholder {
    color: #9ba6b7;
    font-size: 20px;
    letter-spacing: -0.26px; }
  .login-page .css-bg1rzq-control {
    background: #fff;
    border: 1px solid #d8e0eb;
    border-radius: 18px;
    min-height: 86px; }
  .login-page .css-bgvzuu-indicatorSeparator {
    display: none; }
  .login-page .otp-main-input input {
    padding: 0; }

.contact-modal {
  font-family: "Sawton Industrial", sans-serif; }

.contact-modal .modal-header {
  border: none;
  background: transparent; }

.contact-modal .modal-header .close {
  position: absolute;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  width: 52px;
  height: 52px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid rgba(119, 130, 147, 0.24);
  right: 38px;
  top: 38px;
  font-size: 26px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  z-index: 1; }

.contact-modal .modal-header .close svg {
  color: #778293;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  -ms-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  height: 12px; }

.contact-modal .modal-header .close:hover {
  border: 2px solid #778293; }

.contact-modal .modal-title {
  font-size: 80px;
  color: #2b2e36;
  letter-spacing: -3.84px;
  text-align: center;
  line-height: 119px;
  margin: 0 auto;
  padding-top: 60px;
  text-transform: capitalize; }

.contact-modal .modal-body {
  padding: 50px 80px; }

.contact-modal .modal-content {
  background: #fff;
  -webkit-box-shadow: -26px 20px 64px -24px rgba(0, 0, 0, 0.05);
  box-shadow: -26px 20px 64px -24px rgba(0, 0, 0, 0.05);
  -webkit-border-radius: 32px 59px 59px 32px;
  border-radius: 32px 59px 59px 32px; }

@media (max-width: 992px) {
  .contact-modal .modal-title {
    font-size: 36px;
    letter-spacing: -1.68px;
    line-height: 42px; }
  .contact-modal .modal-body {
    padding: 30px 15px; } }

.modal-body .login-page {
  background-color: transparent;
  width: auto !important;
  margin-left: 0; }

@media only screen and (min-width: 992px) {
  .isloginpage #main-wrapper .page-wrapper {
    top: 0;
    height: auto;
    margin-left: 0; } }
